<template>
  <VxCard class="mb-6 pie-chart" :title="title">
    <VsRow slot="no-body" class="h-1" vs-type="flex">
      <VsProgress v-if="loading" indeterminate color="primary" height="1">primary</VsProgress>
    </VsRow>

    <!-- Filtre Date -->
    <template v-if="dateFilter">
      <VsCol class="mt-3 ml-3">
        <label><small>Par date de création</small></label>
      </VsCol>

      <VsRow>
        <VsCol class="p-3" vs-xs="12" vs-lg="6">
          <FlatPickr
            :config="flatPickr"
            placeholder="Date début"
            v-model="filter.startDate"
          />
        </VsCol>

        <VsCol class="p-3" vs-xs="12" vs-lg="6">
          <FlatPickr
            :config="flatPickr"
            placeholder="Date début"
            v-model="filter.endDate"
          />
        </VsCol>
      </VsRow>
    </template>

    <!-- Graphique -->
    <VueApexCharts
      v-if="series"
      type="pie"
      height="334"
      class="my-12"
      :options="chartOptions"
      :series="series"
    />

    <!-- Legende -->
    <VsTable :data="data">
      <template v-if="labels && labels.length" slot="thead">
        <vs-th class="text-center" v-for="label in labels" :key="label">
          {{ label }}
        </vs-th>
      </template>
      <template slot-scope="{data: items}">
        <VsTr v-for="(item) in items" :key="item.label">
          <VsTd>
            <span
              :class="[
                'h-3', 'w-3', 'mr-1',
                'inline-block',
                'rounded-full'
              ]"
              :style="{backgroundColor: item.color}"
            />
            {{ item.label }}
          </VsTd>
          <VsTd>{{ item.value }}</VsTd>
          <VsTd v-for="cell in item.optionalValues" :key="cell">
            {{ cell }}
          </VsTd>
        </VsTr>
      </template>
    </VsTable>
  </VxCard>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import { parse, subMonths } from 'date-fns';

export default {
  name: 'CardsPieChart',
  components: {
    VueApexCharts,
  },
  props: {
    data: { type: Array, default: () => [] },
    dateFilter: { type: Boolean, default: () => true },
    dateFilterData: {
      type: Object,
      default: () => ({
        startDate: subMonths(new Date(), 6),
        endDate: new Date(),
      }),
    },
    labels: { type: Array, default: () => null },
    loading: { type: Boolean, default: () => false },
    thead: { type: Array, default: () => [] },
    title: { type: String, default: () => 'Tableaux de bord' },
  },
  data() {
    return {
      filter: this.dateFilterData,
      flatPickr: {
        dateFormat: 'd/m/Y',
      },
      chartOptions: {
        dataLabels: { enabled: true },
        legend: { show: false },
        chart: {
          offsetY: 0,
          dropShadow: {
            enabled: false,
            blur: 5,
            left: 1,
            top: 1,
            opacity: 0.2,
          },
        },
        stroke: {
          width: 2,
        },
      },
      orders: [],
    };
  },
  watch: {
    data(value) {
      this.chartOptions = {
        ...this.chartOptions,
        labels: value.map(({ label }) => label),
        colors: value.map(({ color }) => color),
      };
    },
    filter: {
      handler() {
        this.changeEvent('onChange');
      },
      deep: true,
    },
  },
  computed: {
    series: {
      get() {
        return this.data.map(({ value }) => value);
      },
      set(value) {
        this.$emit('series', value);
      },
    },
  },
  mounted() {
    this.changeEvent('onMounted');
  },
  methods: {
    changeEvent(eventName) {
      this.$emit(eventName, {
        startDate: this.formatDate(this.filter.startDate),
        endDate: this.formatDate(this.filter.endDate),
      });
    },
    formatDate(date) {
      return typeof date === 'string' ? parse(date, 'dd/MM/yyyy', new Date()) : date;
    },
  },
};
</script>
