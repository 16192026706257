<template>
  <div id="card-analytics">
    <!-- commandes non servies depuis un mois -->
    <VxCard class="mb-6 bg-warning" title="Commandes non servies depuis un mois">
      <template slot="actions">
        <FeatherIcon icon="AlertTriangleIcon" svgClasses="w-10 h-10 text-black" />
      </template>

      <div slot="no-body">
        <!-- CHART -->
        <VsRow>
          <VsCol
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="8"
            vs-sm="12"
            vs-xs="12"
            class="space-10"
          >
            <b class="bold-text text-black"> {{ unservedOrders.total }}</b>
            commandes en cours non servies depuis le {{ monthAgo }}
          </VsCol>

          <VsCol
            vs-type="flex"
            vs-justify="left"
            vs-align="center"
            vs-lg="4"
            vs-sm="12"
            vs-xs="12"
            class="space-10"
          >
            <VsButton
              class="mr-3"
              color="dark"
              type="border"
              @click="exportUnservedOrders()"
            >
              Exporter csv
            </VsButton>
          </VsCol>
        </VsRow>
        <div v-if="superAdmin" class="flex justify-between text-center">
          <div
            class="
              w-1/2
              border border-solid
              d-theme-border-grey-light
              border-r-0 border-b-0 border-l-0
            "
          >
            <h3 class="mt-4 text-3xl font-semibold">{{ unservedOrders.PP }}</h3>
            <p class="mb-4 text-xl">PP</p>
          </div>

          <div
            class="
              w-1/2
              border border-solid
              d-theme-border-grey-light
              border-r-0 border-b-0
            "
          >
            <h3 class="mt-4 text-3xl font-semibold">{{ unservedOrders.UP }}</h3>
            <p class="mb-4 text-xl">UPInteractions</p>
          </div>
          <div
            class="
              w-1/2
              border border-solid
              d-theme-border-grey-light
              border-r-0 border-b-0
            "
          >
            <h3 class="mt-4 text-3xl font-semibold">{{ unservedOrders.EuroCRM }}</h3>
            <p class="mb-4 text-xl">EuroCRM</p>
          </div>
          <div
            class="
              w-1/2
              border border-solid
              d-theme-border-grey-light
              border-r-0 border-b-0
            "
          >
            <h3 class="mt-4 text-3xl font-semibold">{{ unservedOrders.ORBICALL }}</h3>
            <p class="mb-4 text-xl">ORBICALL</p>
          </div>
        </div>
      </div>
    </VxCard>

    <!-- Commande par statut -->
    <CardsPieChart
      title="Nombre de Commandes par statut"
      :data="ordersByStatus.data"
      :labels="['Statuts', 'Nb. commandes']"
      :loading="ordersByStatus.loading"
      @onChange="getOrdersByStatus"
      @onMounted="getOrdersByStatus"
    />

    <!-- rendez-vous par Formule -->
    <CardsPieChart
      v-if="superAdmin"
      title="Nombre de rendez-vous par formule"
      :data="appointmentsByFormula.data"
      :labels="['Formules', 'Nb. rendez-vous']"
      :loading="appointmentsByFormula.loading"
      @onChange="getAppointmentByFormula"
      @onMounted="getAppointmentByFormula"
    />

    <!-- Comandes Par Préstataire -->
    <CardsPieChart
      v-if="superAdmin"
      title="Nombre de Commandes par préstataire"
      :data="ordersByServiceProvider.data"
      :dateFilterData="{ startDate: null, endDate: null }"
      :labels="['Prestataires', 'Nb. commandes', 'Pot. annonces']"
      :loading="ordersByServiceProvider.loading"
      @onChange="getOrdersByServiceProvider"
      @onMounted="getOrdersByServiceProvider"
    />

    <!-- Commande par formule -->
    <OrdersByFormula />

    <!-- Demandes d'annulation-->
    <CardsPieChart
      title="Demandes d'annulation de rendez-vous"
      :data="cancellationRequests.data"
      :date-filter="false"
      :labels="['Motifs', 'Nb. d\'annulation']"
      :loading="cancellationRequests.loading"
      @onMounted="getCancellationRequests"
    />
  </div>
</template>

<script>
import moment from 'moment';
import slugify from 'slugify';

import CardsPieChart from '@/components/Cards/PieChart.vue';
import OrdersByFormula from '@/components/pages/admin/dashboard/OrdersByFormula.vue';
import adminAnalytics from './adminAnalytics';

import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/legend';
import 'echarts/lib/chart/pie';

export default {
  name: 'AdminDashboard',
  components: {
    CardsPieChart,
    OrdersByFormula,
  },
  data() {
    return {
      // Commandes par status
      orders: [],
      startDate: '',
      endDate: '',
      series: [],
      monthAgo: new Date(),

      // Commandes par prestataire
      providerStartDate: '',
      providerEndDate: '',
      ordersByProvider: [],
      ProviderSeries: [],

      // Commandes non servies
      unservedOrders: {
        total: '',
        PP: '',
        UP: '',
        EuroCRM: '',
        ORBICALL: ''
      },

      // Rendez-vous par formule
      appointmentByFormulaStartDate: '',
      appointmentByFormulaEndDate: '',
      appointmentByFormulaSeries: [],

      superAdmin: false,
      dateTimePickerConfiguration: {
        enableTime: true,
        dateFormat: 'Y-m-d',
      },

      adminAnalytics,

      ordersByStatus: {
        data: [],
        loading: false,
      },

      ordersByServiceProvider: {
        data: [],
        loading: false,
      },

      appointmentsByFormula: {
        data: [],
        loading: false,
      },

      cancellationRequests: {
        data: [],
        loading: false,
      },
    };
  },

  methods: {
    /**
     * Récupère le nombre du total de commandes pour chaque status
     */
    async getOrdersByStatus({ startDate, endDate }) {
      this.ordersByStatus.loading = true;

      const url = this.$urlBuilder('/api/commandes/statut')
        .appendQuery('dateDebut', startDate.toISOString())
        .appendQuery('dateFin', endDate.toISOString())
        .appendQuery('all', false);

      try {
        const { data } = await this.$http.get(url.format());
        const status = data.filter(({ count, statut }) => {
          const exludedStatus = ['ALL', 'CLO'];

          return (statut && !exludedStatus.includes(statut.trim()) && count);
        });

        this.ordersByStatus.data = status.map((value) => {
          const label = this.$options.filters.normalizeStatus(value.statut);
          return {
            label,
            color: `var(--color-${slugify(value.statut, { lower: true })})`,
            value: value.count,
          };
        });
      } catch (error) {
        this.$toast.error(`getOrdersByStatus : ${error.message}`);
      }
      this.ordersByStatus.loading = false;
    },

    /**
     * Récupère le nombre du total de commandes par préstataire
     */
    async getOrdersByServiceProvider({ startDate, endDate }) {
      if (!this.$store.state.AppActiveUser.isSuperAdmin) return;

      this.ordersByServiceProvider.loading = true;

      const url = this.$urlBuilder('/api/commandes/prestataire')
        .appendQuery('all', false);
      if (startDate) {
        url.appendQuery('dateDebut', startDate.toISOString());
      }
      if (endDate) {
        url.appendQuery('dateFin', endDate.toISOString());
      }

      try {
        const { data } = await this.$http.get(url.format());
        const serviceProviders = data.filter(({ count }) => count);

        this.ordersByServiceProvider.data = serviceProviders.map((value) => ({
          label: value.prestataire,
          color: `var(--color-${slugify(value.prestataire, { lower: true })})`,
          value: value.count,
          optionalValues: [new Intl.NumberFormat().format(value.ads)],
        }));
      } catch (error) {
        this.$vs.notify({
          title: 'erreur',
          text: this.$parseError(error).message,
          color: 'danger',
        });
      }

      this.ordersByServiceProvider.loading = false;
    },

    /**
     * Récupère le nombre du total de rendez-vous par formule
     */
    async getAppointmentByFormula() {
      if (!this.$store.state.AppActiveUser.isSuperAdmin) return;

      this.appointmentsByFormula.loading = true;

      const url = this.$urlBuilder('/api/rendez_vous/formule')
        .appendQuery('dateDebut', this.appointmentByFormulaStartDate, true)
        .appendQuery('dateFin', this.appointmentByFormulaEndDate, true);

      try {
        const { data } = await this.$http.get(url.format());

        this.appointmentsByFormula.data = data.map((value) => ({
          label: value.formule,
          color: `var(--color-${slugify(value.formule, { lower: true })})`,
          value: value.count,
        }));
      } catch (error) {
        this.$toast.error(`getAppointmentByFormula : ${error.message}`);
      }
      this.appointmentsByFormula.loading = false;
    },

    /**
     * Récupère le nombre du total de commandes non servie par prestataire
     */
    async getUnservedOrders() {
      const { id, roles } = this.$store.state.AppActiveUser.user;

      const url = this.$urlBuilder('/api/commandes/non-servies')
        .appendQuery('teleconseiller', roles.includes('ROLE_SUPER_ADMIN') ? undefined : id);

      const { data } = await this.$http.get(url.format());

      this.unservedOrders = data;
      this.monthAgo = moment(this.monthAgo).subtract(1, 'M').format('DD/M/YYYY');
    },

    /**
     * Récupère les demande d'annulation et les bind dans une configuration de graph
     */
    async getCancellationRequests() {
      this.cancellationRequests.loading = true;

      const { roles, id } = this.$store.state.AppActiveUser.user;
      const url = roles.includes('ROLE_SUPER_ADMIN')
        ? '/api/demandes/motif/'
        : `/api/demandes/motif/${id}`;

      try {
        const { data } = await this.$http.get(url);

        this.cancellationRequests.data = data.map((value) => ({
          label: value.name,
          color: `var(--color-${slugify(value.name, { lower: true })})`,
          value: value.value,
        }));
        this.adminAnalytics.pieDemandesAnnulations.series[0].data = data;
        this.adminAnalytics.pieDemandesAnnulations.legend.data = data;
      } catch (error) {
        this.$toast.error(`getAppointmentByFormula : ${error.message}`);
      }
      this.cancellationRequests.loading = false;
    },

    /**
     * Force un téléchargement
     */
    forceFileDownload(response) {
      const csvContent = response.data;
      const link = window.document.createElement('a');
      link.setAttribute('href', `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURI(csvContent)}`);
      link.setAttribute('download', 'upload_data.csv');
      link.click();
    },

    /**
     * Récupère un csv généré depuis le back
     */
    async exportUnservedOrders() {
      const { roles, id } = this.$store.state.AppActiveUser.user;

      const url = this.$urlBuilder('/api/commandes/non-servies/export')
        .appendQuery('teleconseiller', roles.includes('ROLE_SUPER_ADMIN') ? undefined : id);

      try {
        const result = await this.$http.get(url.format());
        this.forceFileDownload(result);
      } catch (error) {
        this.$toast.error('export impossible');
      }
    },
  },
  async created() {
    const { roles } = this.$store.state.AppActiveUser.user;
    this.superAdmin = roles.includes('ROLE_SUPER_ADMIN');

    // Initialise les requêtesde récupération de données
    this.getUnservedOrders();
    this.getAppointmentByFormula();
    this.getCancellationRequests();
  },
};
</script>

<style>
.bold-text {
  font-size: 38px;
  padding: 20px;
  color: black;
  font-weight: 900 !important;
}
.bg-warning .vx-card__header {
  background-color: var(--vs-warning) !important;
}

@media screen and (min-width: 1024px) {
  #card-analytics {
    columns: 2;
    column-gap: 1.5rem;
  }
  .vx-card {
    background: #fff;
    display: inline-block;
  }
}
</style>
