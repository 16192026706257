<template>
  <VxCard title="Nombre de Commandes par formule" class="mb-6">
    <VsRow slot="no-body" class="h-1" vs-type="flex">
      <VsProgress v-if="loading" indeterminate color="primary" height="1">primary</VsProgress>
    </VsRow>

    <!-- CHART -->
    <VsCol class="mt-3 ml-3">
      <label><small>Par date de prise en charge</small></label>
    </VsCol>

    <VsRow>
      <VsCol class="p-3" vs-xs="12" vs-lg="6">
        <FlatPickr
          :config="flatPickr"
          placeholder="Date début"
          v-model="filter.startDate"
        />
      </VsCol>

      <VsCol class="p-3" vs-xs="12" vs-lg="6">
        <FlatPickr
          :config="flatPickr"
          placeholder="Date début"
          v-model="filter.endDate"
        />
      </VsCol>
    </VsRow>

    <VueApexCharts
      class="p-3"
      type="bar"
      height="350"
      :options="chartOptions"
      :series="formuleSerie"
    />
  </VxCard>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import {
  format, intlFormat, parse, subMonths,
} from 'date-fns';

export default {
  components: {
    VueApexCharts,
  },
  data() {
    return {
      filter: {
        startDate: subMonths(new Date(), 6),
        endDate: new Date(),
      },
      loading: false,
      flatPickr: {
        dateFormat: 'd/m/Y',
      },
      chartOptions: {
        colors: ['#ead121', '#44cfe4', '#ff007c', '#7ED67D', '#737373'],
        chart: {
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [],
        },
      },
      formuleSerie: [],
    };
  },
  watch: {
    filter: {
      handler() {
        this.getFormule();
      },
      deep: true,
    },
  },
  mounted() {
    this.getFormule();
  },
  methods: {
    async getFormule() {
      this.loading = true;

      const { user } = this.$store.state.AppActiveUser;
      const first = typeof this.filter.startDate === 'string' ? parse(this.filter.startDate, 'dd/MM/yyyy', new Date()) : this.filter.startDate;
      const last = typeof this.filter.endDate === 'string' ? parse(this.filter.endDate, 'dd/MM/yyyy', new Date()) : this.filter.endDate;

      const searchParamsUrl = new URLSearchParams();
      searchParamsUrl.append('datePriseCharge[after]', first.toISOString());
      searchParamsUrl.append('datePriseCharge[before]', last.toISOString());
      searchParamsUrl.append('properties[]', 'formule');
      searchParamsUrl.append('properties[]', 'datePriseCharge');
      searchParamsUrl.append('statut[]', 'EC');
      searchParamsUrl.append('employee[]', user.id);
      if (user.roles.includes('ROLE_SUPER_ADMIN')) {
        searchParamsUrl.delete('employee[]');
      }

      let data = [];

      try {
        const response = await this.$http.get(`/api/commandes?${searchParamsUrl.toString()}`);

        data = response.data;
      } catch (err) {
        this.$toasted.show('Une erreur est survenue! Veuillez actualiser la page.').goAway(1500);
      }

      const { categories } = this.chartOptions.xaxis;
      const formula = {};
      const xaxis = {};

      data.forEach(({ formule, datePriseCharge: formulaDate }) => {
        const date = format(new Date(formulaDate), 'yyyy/MM');

        if (!xaxis[date]) {
          Object.assign(xaxis, { [date]: intlFormat(new Date(formulaDate), { month: 'short' }, { locale: 'fr-FR' }) });
        }

        if (!formula[formule]) {
          Object.assign(formula, { [formule]: {} });
        }

        if (formula[formule][date]) {
          Object.assign(formula[formule], { [date]: formula[formule][date] + 1 });
        } else {
          Object.assign(formula[formule], { [date]: 1 });
        }
      });

      const series = Object.entries(formula).map(([key, value]) => ({
        name: key,
        data: Object.values(value),
      })).sort((a, b) => (a.name < b.name ? -1 : 1));

      this.formuleSerie = series;
      categories.splice(0, categories.length, ...Object.values(xaxis));

      this.loading = false;
    },
  },
};
</script>
